















import { Component, Vue, Prop } from 'vue-property-decorator';
import Sidebar from '@/components/swine/layout/Sidebar.vue';
import Navbar from '@/components/swine/layout/Navbar.vue';

@Component({ components: { Sidebar, Navbar } })
export default class Page extends Vue {
  @Prop({
    type: Boolean,
    default: true,
  })
  showUserProfile!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  showSidebar!: boolean;
}
